import { Md5Utils } from "@/sea/utils/Md5Utils";

export class UserUtils {
  public static generateGravatarUrl(email: string, s: number | null = null) {
    if (email) {
      let url = `https://www.gravatar.com/avatar/${Md5Utils.calcMd5(
        email.trim()
      )}?d=identicon`;

      if (s && s > 0) {
        url += `&s=${s}`;
      }

      return url;
    } else {
      return "https://www.sushieden.co.nz/assets/img/logo_sushi.png";
    }
  }
}
