
import { defineComponent } from "vue";
import { useStore } from "vuex";
import { UserUtils } from "@/sea/utils/UserUtils";
import { I18nUtils } from "@/sea/utils/I18nUtils";

export default defineComponent({
  name: "kt-account",
  components: {},
  setup() {
    const store = useStore();

    const username = store.getters.currentUser?.username;
    const email = store.getters.currentUser?.email;

    return {
      username,
      email,
      UserUtils,
      I18nUtils,
    };
  },
});
